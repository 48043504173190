import {
  AfterViewInit,
  booleanAttribute,
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  input,
  NgZone,
  OnDestroy,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';

@UntilDestroy()
@Directive({ selector: '[ultraScroll]', standalone: true })
export class ScrollDirective implements AfterViewInit, OnDestroy {
  @Input() hideScroll = true;
  @Input() noAnimation = false;
  @Input() theme: 'dark' | 'light' | 'grey-medium-dark' = 'light';
  useBottomGradient = input<boolean, (v: unknown) => boolean>(false, { transform: booleanAttribute });
  useTopGradient = input<boolean, (v: unknown) => boolean>(false, { transform: booleanAttribute });
  @Output() scrollYReachEnd = new EventEmitter<void>();

  private animationData: {
    start: number | undefined;
    previousTimeStamp: number | undefined;
    toState: boolean | undefined;
    done: boolean;
  };
  private readonly animationDuration = 300;
  private readonly maxOpacity = 0.1;
  private readonly opacityDeltaPerMs = this.maxOpacity / this.animationDuration;
  private elRef = inject(ElementRef);
  private ngZone = inject(NgZone);
  private hash = `${Math.random()}`.replace('.', '');
  private observer: ResizeObserver;
  private root: HTMLElement = document.querySelector(':root');

  ngOnDestroy(): void {
    if (!this.noAnimation) {
      document.getElementById(`scroll-styles_${this.hash}`)?.remove();
    }
    this.observer?.disconnect();
  }

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.setupScrollCSSAndHTML();
      fromEvent<Event>(this.elRef.nativeElement, 'scroll')
        .pipe(untilDestroyed(this))
        .subscribe((e) => this.onScroll(e));
      fromEvent<Event>(this.elRef.nativeElement, 'mouseenter')
        .pipe(untilDestroyed(this))
        .subscribe(() => this.toggleScrollVisibility(true));
      fromEvent<Event>(this.elRef.nativeElement, 'mouseleave')
        .pipe(untilDestroyed(this))
        .subscribe(() => this.toggleScrollVisibility());
      this.listenHostResize();
    });
  }

  private listenHostResize(): void {
    let currentHeight = 0;
    let currentWidth = 0;
    this.observer = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        entries.forEach((entry) => {
          if (currentHeight !== entry.contentRect.height || currentWidth !== entry.contentRect.width) {
            this.setupGradients();
          }
          currentHeight = entry.contentRect.height;
          currentWidth = entry.contentRect.width;
        });
      });
    });

    this.observer.observe(this.elRef.nativeElement);
  }

  private onScroll(event: Event): void {
    event.stopPropagation();
    const target = event.target as HTMLElement;
    const isFullyScrolled = Math.ceil(target.scrollTop + target.clientHeight) >= target.scrollHeight;
    const isScrolledTop = target.scrollTop < 1;

    if (this.useBottomGradient()) {
      this.elRef.nativeElement.classList[isFullyScrolled ? 'add' : 'remove']('custom-scroll--bottom-gradient-hidden');
    }
    if (this.useTopGradient()) {
      this.elRef.nativeElement.classList[isScrolledTop ? 'add' : 'remove']('custom-scroll--top-gradient-hidden');
    }
    if (isFullyScrolled) {
      this.scrollYReachEnd.emit();
    }
  }

  scrollToY(positionY: number): void {
    setTimeout(() => (this.elRef.nativeElement.scrollTop = positionY));
  }

  private animateScrollThumb(timeStamp: number, show: boolean): void {
    if (this.animationData.toState !== show) {
      // animation state is changed => stop current animation
      return;
    }
    if (this.animationData.start === undefined) {
      this.animationData.start = timeStamp;
    }
    const elapsed = timeStamp - this.animationData.start;
    // no need to trigger layout processes too often, most people physically can't see it
    const shouldStartRedraw = timeStamp - this.animationData.previousTimeStamp > 30; // around 33FPS
    if (this.animationData.previousTimeStamp !== timeStamp) {
      let opacity = this.opacityDeltaPerMs * elapsed;
      opacity = show ? Math.min(opacity, this.maxOpacity) : Math.max(this.maxOpacity - opacity, 0);
      if (shouldStartRedraw) {
        this.updateColorVariable(`rgba(255, 255, 255, ${opacity})`);
      }
      if (show ? opacity === this.maxOpacity : opacity === 0) this.animationData.done = true;
    }

    if (elapsed < this.animationDuration) {
      if (!this.animationData.previousTimeStamp || shouldStartRedraw) {
        this.animationData.previousTimeStamp = timeStamp;
      }
      if (!this.animationData.done) {
        requestAnimationFrame(this.animateScrollThumb.bind(this, Date.now(), show));
      }
    } else {
      this.updateColorVariable(`rgba(255, 255, 255, ${show ? this.maxOpacity : 0})`);
    }
  }

  private get isContentScrollable(): boolean {
    return this.elRef.nativeElement.scrollHeight > this.elRef.nativeElement.clientHeight;
  }

  private setupScrollCSSAndHTML(): void {
    this.elRef.nativeElement.classList.add('custom-scroll');
    this.elRef.nativeElement.classList.add(`custom-scroll--${this.theme}`);
    if (this.noAnimation) {
      this.elRef.nativeElement.classList.add(`custom-scroll--no-animation`);
      if (this.hideScroll) {
        this.elRef.nativeElement.classList.add('custom-scroll--hidden');
      }
    } else {
      this.elRef.nativeElement.classList.add(`custom-scroll--${this.hash}`);
      this.updateColorVariable(`rgba(255, 255, 255, 0)`);
      const style = document.createElement('style');
      style.innerHTML = `
        .custom-scroll--${this.hash}::-webkit-scrollbar-thumb {
          background: var(--scroll-background-${this.hash});
        }
      `;
      style.id = `scroll-styles_${this.hash}`;
      document.head.appendChild(style);
    }
    this.setupGradients();
  }

  private setupGradients(): void {
    setTimeout(() => {
      // we have to ensure that the layout will be updated, therefore we have to run this code in the next event loop
      if (this.isContentScrollable) {
        if (this.useBottomGradient()) {
          this.elRef.nativeElement.classList.add('custom-scroll--bottom-gradient');
        }
        if (this.useTopGradient()) {
          this.elRef.nativeElement.classList.add('custom-scroll--top-gradient');
          if (this.elRef.nativeElement.scrollTop < 1) {
            this.elRef.nativeElement.classList.add('custom-scroll--top-gradient-hidden');
          }
        }
        this.elRef.nativeElement.classList.add('custom-scroll--active');
      } else {
        this.elRef.nativeElement.classList.remove(
          'custom-scroll--bottom-gradient',
          'custom-scroll--top-gradient',
          'custom-scroll--top-gradient-hidden',
          'custom-scroll--bottom-gradient-hidden',
          'custom-scroll--active',
        );
      }
    });
  }

  private toggleScrollVisibility(show = false): void {
    if (this.noAnimation) {
      if (this.hideScroll) {
        this.elRef.nativeElement.classList[show ? 'remove' : 'add']('custom-scroll--hidden');
      }
    } else {
      this.resetAnimationData(show);
      // ATM there is no way to use transition/animations for scroll properties,
      // therefore this solution with CSS variables was implemented
      this.animateScrollThumb(Date.now(), show);
    }
  }

  private resetAnimationData(show: boolean): void {
    this.animationData = {
      start: undefined,
      previousTimeStamp: undefined,
      toState: show,
      done: false,
    };
  }

  private updateColorVariable(color: string): void {
    this.root.style.setProperty(`--scroll-background-${this.hash}`, color);
  }
}
