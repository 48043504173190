import { inject, Injectable } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';

import { UserCurrency } from '@ultra/core/models';

import { AlgoliaSearchService } from '../../algolia/algolia-search.service';
import { CurrencyService } from '../../algolia/currency.service';
import { resortArray } from '../../helpers/resort-array.util';
import { IUniqFactory } from '../uniq-factory.interface';

import { AlgoliaUniqFactoryAdapter } from './uniq-factory.adapter';

export enum UniqFactoriesSearchIndex {
  UNIQ_FACTORIES_PRICE_USD_DESC = 'uniq_factories_price_USD_desc',
  UNIQ_FACTORIES_PRICE_USD_ASC = 'uniq_factories_price_USD_asc',
  UNIQ_FACTORIES_CREATED_DESC = 'uniq_factories_created_at_desc',
}

@Injectable({ providedIn: 'root' })
export class UniqFactoryAlgoliaService {
  private algoliaSearchService = inject(AlgoliaSearchService);
  private currencyService = inject(CurrencyService);

  uniqFactoriesByIds$(
    ids: string[],
    attributesToRetrieve?: string[],
    indexName: UniqFactoriesSearchIndex = UniqFactoriesSearchIndex.UNIQ_FACTORIES_CREATED_DESC,
  ): Observable<IUniqFactory[]> {
    return this.algoliaSearchService
      .search({
        indexName,
        filters: ids.map((id) => `on_chain_id:${id}`).join(' OR '),
        length: ids.length,
        attributesToRetrieve,
      })
      .pipe(
        switchMap((response) =>
          this.currencyService.userCurrency$.pipe(
            map((currency: UserCurrency) =>
              response.results[0]['hits'].map((item) => AlgoliaUniqFactoryAdapter.transform(item, currency)),
            ),
          ),
        ),
        // We receive a different order from algolia, so we need to sort it again
        map((uniqFactories) => resortArray(ids, uniqFactories, 'onChainId')),
      );
  }
}
